/* ArtistTourDates.css */
.tour-date-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  border-radius: 8px;
  padding: 16px;
  margin: 10px 0; /* Vertical spacing between items */
  background-color: #ffffff; /* White background for card */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.tour-date-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards; /* Fade-in effect */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
