/* src/TopArtists.css */
.top-artists {
    text-align: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .artist-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .artist-item {
    background-color: #bbdefb; /* Light Blue Card Background */
    border-radius: 15px;
    padding: 15px;
    text-align: center;
    width: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .artist-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
    border: 2px solid #1976d2; /* Blue Border for Images */
  }
  
  .artist-name {
    font-size: 14px;
    font-weight: bold;
    color: #0d47a1; /* Dark Blue Text */
    margin: 0;
  }
  