.App {
  background-color: #d0d7fc; /* Light gray background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.logged-in {
  flex-grow: 1;
}

h1, h2, h3 {
  color: #1e1e1e; /* Darker text for better readability */
}

.card {
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
}

.card:hover {
  transform: scale(1.05); /* Scale effect on hover */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}
