.login-page {
    position: relative;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
}

/* Background animations */
.background-animations {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4; /* Lower than login box */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    pointer-events: none; /* Prevent animations from blocking clicks */
}

.animation-1,
.animation-2 {
    position: absolute;
    border-radius: 50%;
    opacity: 0.5;
}

.animation-1 {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    width: 300px;
    height: 300px;
    animation: move 6s infinite;
}

.animation-2 {
    background: radial-gradient(circle, rgba(0, 255, 0, 0.3), rgba(0, 255, 0, 0));
    width: 250px;
    height: 250px;
    animation: move2 8s infinite;
}

/* Keyframes for animation */
@keyframes move {
    0% {
        transform: translate(-50%, -50%);
    }
    50% {
        transform: translate(50%, 50%);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}

@keyframes move2 {
    0% {
        transform: translate(50%, 50%);
    }
    50% {
        transform: translate(-50%, -50%);
    }
    100% {
        transform: translate(50%, 50%);
    }
}

/* Bounce effect for the company logo */
.company-logo {
    width: 200px; /* Adjust the width as needed */
    height: auto; /* Keep the aspect ratio */
    margin-bottom: 16px;
    animation: bounce 1.5s infinite;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}
