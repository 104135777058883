.ticket-purchase-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin: 20px 0;
}

.new-card-form {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 16px;
}

.payment-method-header {
    margin-bottom: 16px;
    font-weight: bold;
}

.section-select,
.ticket-count-select {
    margin-bottom: 16px;
}

.bold {
    font-weight: bold;
}

.checkout-header {
    margin-bottom: 16px;
}

.payment-methods {
    margin-bottom: 16px;
}

.error-message {
    color: #f44336;
}

.close-button {
    float: right;
    color: #f44336;
}
