.AppBar {
  background-color: white;
  box-shadow: none;
}

.Button {
  color: #4766c6; /* Primary color */
}

.Button:hover {
  background-color: #e0e0e0; /* Light gray on hover */
}

.Menu {
  background-color: #f9f9f9; /* Light background for menu */
}
